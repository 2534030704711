<template>
	<modal class="NoxModalSettings" name="NoxModalSettings" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="(noxType !== 'isAgreement' ? true : false)" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close" v-if="noxType !== 'isAgreement'"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'isAgreement'" v-html="$store.getters.getLanguageText('5.9', 0)"></span>
					<span v-else-if="noxType == 'editEmail'" v-html="$store.getters.getLanguageText('5.9', 2)"></span>
				</div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div v-if="noxType == 'isAgreement'" v-html="$store.getters.getLanguageText('5.9', 1)"></div>
					<div v-else-if="noxType == 'editEmail'" v-html="$store.getters.getLanguageText('5.9', 3, { email: $parent.noxSettings1.email_new })"></div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', (noxType == 'isAgreement' ? 50 : (noxType == 'editEmail' ? 9 : 3)))"></button>
					<button type="button" class="nox_button common green" @click="axios" v-if="noxType == 'isAgreement'" v-html="$store.getters.getLanguageText('1.1', 51)"></button>
					<button type="button" class="nox_button common green" @click="editEmail" v-else-if="noxType == 'editEmail'" v-html="$store.getters.getLanguageText('1.1', 118)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxType: '',
			noxSType: '',
			noxValue: '',
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxType = event.params.type;
				this.noxSType = event.params.stype;
				this.noxValue = event.params.value;
				this.noxIsLoading = false;
				this.noxIsError = false;
			},
			resetAlerts: function() {
				this.noxAlert = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 18); }
				else        { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (_this.noxType == 'isAgreement') {
						config.url = '/v2/account/users/data/is_agreement';
						config.data = { is_agreement: 1 };
						config.method = 'patch';
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.noxType == 'isAgreement') {
									_this.close();
									_this.$parent.noxSettings3.is_agreement = 1;
									_this.$parent.axios(_this.noxSType, _this.noxValue);
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (_this.noxType == 'isAgreement') {
										if      (data.response.data.error == 'IS_AGREEMENT_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'IS_AGREEMENT_NOT_VALID') { _this.noxAlert = _this.getError(1); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			editEmail: function() {
				this.close();
				this.$parent.noxSettings1.email_is_new = true;
				this.$parent.axios(this.noxSType, this.noxValue);
			},
			close: function() {
				this.$modal.hide('NoxModalSettings');
			}
		}
	}
</script>
